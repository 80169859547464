import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
import fetchChallenge from "../../api/fetchChallenge";
import fetchFile from "../../api/fetchFile";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";
import fetchMediatoken from "../../api/fetchMediatoken";
import axios from "axios";
import { Carousel } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import ChallengeComments from "./ChallengeComments";

import SubscriptionModal from "../Subscription/SubscriptionModal";

const ChallengeDetail = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [challengeData, setChallengeData] = useState(null);
  const [filesDataArray, setFilesDataArray] = useState([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [withSubscription, setWithSubscription] = useState(false);
  const [viewedVideos, setViewedVideos] = useState([]); // Dodajte stanje za pregledane videozapise
  const [mediaToken, setMediaToken] = useState(null);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchChallenge.get(`/${id}`);
        console.log(response);
        setChallengeData(response.data.data.challenge);

        const viewedVideosResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/viewed_videos/${user.id}`
        );
        setViewedVideos(viewedVideosResponse.data.data.viewed_videos);

        if (response.data.data.challenge.with_subscription === true) {
          setWithSubscription(true);
          try {
            const responseSubscription = await fetchSubscriptionmetabyuser.get(
              `/${user.id}`
            );
            if (responseSubscription.data.status === "success") {
              setIsPurchased(true);
            }
          } catch (error) {
            setIsPurchased(false);
          }
        } else {
        }

        // Provjeri je li korisnik kupio challenge
        const purchasedResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=${id}&product_category=challenge`
        );

        if (purchasedResponse.data.status === "success") {
          const endDate = new Date(response.data.data.challenge.end_date);
          const currentDate = new Date();
          console.log(endDate);
          console.log(currentDate);
          if (currentDate > endDate) {
            setIsPurchased(false);
          } else {
            setIsPurchased(true);
          }
        }
      } catch (err) {
        console.error("Error fetching challange:", err);
      }
    };

    fetchData();
  }, [id, user]);

  useEffect(() => {
    const fetchFilesData = async () => {
      try {
        if (challengeData && challengeData.files_ids) {
          const filesArray = challengeData.files_ids
            .replace(/[{}"]/g, "")
            .split(",");

          const fetchedFilesDataArray = await Promise.all(
            filesArray.map(async (fileId) => {
              const fileResponse = await fetchFile.get(`/${fileId}`);
              return fileResponse.data.data.file;
            })
          );
          setFilesDataArray(fetchedFilesDataArray);
        }
      } catch (err) {
        console.error("Error fetching files data:", err);
      }
    };
    fetchFilesData();
  }, [challengeData]);

  const openVideo = async (videoID) => {
    try {
      const response = await fetchMediatoken.get(`/${user.id}/${videoID}`);
      const mediaToken = response.data.mediatoken;
      if (mediaToken) {
        // Redirekcija na /app/player/${video.id}/?mediatoken=
        navigate(`/app/player/${videoID}/${mediaToken}`);
      } else {
        console.error("Video token is not generated.");
      }
    } catch (error) {
      console.error("Error generating video token:", error);
    }
  };

  const openPdf = async (fileID) => {
    try {
      const response = await fetchMediatoken.get(`/${user.id}/${fileID}`);
      const mediaToken = response.data.mediatoken;
      if (mediaToken) {
        // Redirekcija na /app/player/${video.id}/?mediatoken=
        navigate(`/app/pdf/${fileID}/${mediaToken}`);
      } else {
        console.error("Video token is not generated.");
      }
    } catch (error) {
      console.error("Error generating video token:", error);
    }
  };

  return (
    <div>
      {challengeData ? (
        <div>
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="md:w-[69%]">
                <Carousel className="relative h-56 overflow-hidden rounded-lg md:h-96">
                  {id === "15" ? (
                    <img
                      src="https://medianfiles.com/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-18-at-16.14.06.jpeg"
                      alt="..."
                    />
                  ) : (
                    <img
                      src="https://medianfiles.com/wp-content/uploads/2024/01/image00017.jpeg"
                      alt="..."
                    />
                  )}
                  <img
                    src="https://medianfiles.com/wp-content/uploads/2024/01/image00011.jpeg"
                    alt="..."
                  />
                </Carousel>

                <div className="py-6">
                  <nav className="flex mb-4" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                      <li className="inline-flex items-center">
                        <a
                          href="/"
                          className="inline-flex items-center text-sm font-medium text-zinc-700 hover:text-blue-600"
                        >
                          <svg
                            className="w-3 h-3 mr-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                          </svg>
                          Početna
                        </a>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="w-3 h-3 text-zinc-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <a
                            href="/challanges"
                            className="ml-1 text-sm font-medium text-zinc-700 hover:text-blue-600 md:ml-2"
                          >
                            Challange
                          </a>
                        </div>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg
                            className="w-3 h-3 text-zinc-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <span className="ml-1 text-sm font-medium md:ml-2 text-zinc-400">
                            {challengeData.title}
                          </span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="text-crimson mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl text-white">
                    {challengeData.title}
                  </h2>
                </div>
                {isPurchased ? (
                  <div>
                    <p className="mb-3 text-zinc-400">
                      {challengeData.description}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {isPurchased ? (
                  <div className="grid lg:grid-cols-3 gap-8 w-full py-12">
                    {challengeData.videos
                      .sort((a, b) => b.id - a.id)
                      .map((video, index) => {
                        const isVideoViewed = viewedVideos.some(
                          (viewedVideo) => viewedVideo.video_id === video.id
                        );

                        return (
                          <div
                            key={video.video_id}
                            className="max-w-sm cursor-pointer"
                          >
                            <div onClick={() => openVideo(video.id)}>
                              <div className="relative">
                                <img
                                  className="rounded-lg"
                                  src={`https://vz-4b5814f0-f31.b-cdn.net/${video.video_id}/thumbnail.jpg`}
                                  alt=""
                                  onMouseOver={(e) => {
                                    e.currentTarget.src = `https://vz-4b5814f0-f31.b-cdn.net/${video.video_id}/preview.webp`;
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.src = `https://vz-4b5814f0-f31.b-cdn.net/${video.video_id}/thumbnail.jpg`;
                                  }}
                                />
                                {isVideoViewed && (
                                  <span className="absolute top-2 left-2 text-white font-bold text-xs">
                                    POGLEDANO
                                  </span>
                                )}
                              </div>

                              <div className="py-2">
                                <h5 className="text-base font-semibold tracking-tight text-white">
                                  {video.bunnyData.title}
                                </h5>
                                <span className="text-zinc-400 text-xs">
                                  {Math.round(video.bunnyData.length / 60)}{" "}
                                  minute
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <></>
                )}

                <Swiper
                  loop={true}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, FreeMode]}
                  className="mySwiper py-12 h-auto"
                  breakpoints={{
                    // kada je širina ekrana >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // kada je širina ekrana >= 640px
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // kada je širina ekrana >= 1024px
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21-1.jpeg" />
                  </SwiperSlide>
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21-2.jpeg" />
                  </SwiperSlide>
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21-3.jpeg" />
                  </SwiperSlide>
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21-4.jpeg" />
                  </SwiperSlide>
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21-5.jpeg" />
                  </SwiperSlide>
                  <SwiperSlide className="relative">
                    <img src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-20-at-06.57.21.jpeg" />
                  </SwiperSlide>
                </Swiper>

                {isPurchased ? (
                  <ChallengeComments user={user} challenge_id={id} />
                ) : (
                  <></>
                )}
              </div>

              <div className="relative md:w-[30%]">
                <div className="mx-auto md:shadow-lg rounded-xl md:w-full bg-zinc-800 text-center md:text-left p-2 md:p-6 md:border border-zinc-900 sticky top-4">
                  {!isPurchased && !withSubscription ? (
                    <>
                      <p className="block text-white text-3xl font-bold py-2">
                        €{challengeData.price}
                      </p>
                      <div className="flex flex-col">
                        <span className="text-sm text-white">
                          Jednokratno plaćanje
                        </span>
                        <span className="text-xs text-zinc-400">
                          PDV uključen u cijenu
                        </span>
                      </div>
                      <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="w-full grid grid-cols-3 py-6">
                    <div className="flex flex-col justify-center content-center items-center">
                      <div className="flex flex-col bg-zinc-700 rounded-full w-20 h-20 rounded-full flex justify-center content-center items-center">
                        <span className="font-bold text-lg text-white">
                          {challengeData.total_duration}
                        </span>
                        <span className="font-normal text-sm text-white">
                          tjedna
                        </span>
                      </div>
                      <span className="text-sm text-white text-center">
                        Ukupno trajanje
                      </span>
                    </div>
                    <div className="flex flex-col justify-center content-center items-center">
                      <div className="flex flex-col bg-zinc-700 rounded-full w-20 h-20 rounded-full flex justify-center content-center items-center">
                        <span className="font-bold text-lg text-white">
                          {challengeData.training_duration}
                        </span>
                        <span className="font-normal text-sm text-white">
                          minuta
                        </span>
                      </div>
                      <span className="text-sm text-white text-center">
                        Trajanje treninga
                      </span>
                    </div>
                    <div className="flex flex-col justify-center content-center items-center">
                      <div className="flex flex-col bg-zinc-700 rounded-full w-20 h-20 rounded-full flex justify-center content-center items-center">
                        <span className="font-bold text-lg text-white">
                          {challengeData.number_of_training}
                        </span>
                        <span className="font-normal text-sm text-white">
                          treninga
                        </span>
                      </div>
                      <span className="text-sm text-white text-center">
                        Broj treninga tjedno
                      </span>
                    </div>
                  </div>
                  {id === "15" ? (
                    <ul className="block text-sm text-white w-full mt-6 mb-6">
                      <li className="mb-3 flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#10b981"
                          viewBox="0 0 1792 1792"
                        >
                          <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                        </svg>
                        <div>12 unaprijed snimljenih treninga </div>
                      </li>
                      <li className="mb-3 flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#10b981"
                          viewBox="0 0 1792 1792"
                        >
                          <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                        </svg>
                        <div>Start 27.5. ( pristup treninzima do 30.6.)</div>
                      </li>
                      <li className="mb-3 flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#10b981"
                          viewBox="0 0 1792 1792"
                        >
                          <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                        </svg>
                        <div>
                          Potrebni rekviziti: bučice ili girja + elastična traka
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {isPurchased ? (
                    <>
                      <ul className="block text-sm text-white w-full mt-6 mb-6">
                        {filesDataArray.map((fileData) => (
                          <a
                            key={fileData.id}
                            target="_blank"
                            href={fileData.url}
                            rel="noopener noreferrer"
                          >
                            <li className="mb-3 flex items-center space-x-4 bg-zinc-700 hover:bg-zinc-600 p-4 rounded-lg cursor-pointer">
                              <svg
                                className="w-6 h-6 text-zinc-100"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M4.5 11H4v1h.5a.5.5 0 0 0 0-1ZM7 5V.13a2.96 2.96 0 0 0-1.293.749L2.879 3.707A2.96 2.96 0 0 0 2.13 5H7Zm3.375 6H10v3h.375a.624.624 0 0 0 .625-.625v-1.75a.624.624 0 0 0-.625-.625Z" />
                                <path d="M19 7h-1V2a1.97 1.97 0 0 0-1.933-2H9v5a2 2 0 0 1-2 2H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1a1.969 1.969 0 0 0 1.933 2h12.134c1.1 0 1.7-1.236 1.856-1.614a.988.988 0 0 0 .07-.386H19a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM4.5 14H4v1a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1h1.5a2.5 2.5 0 1 1 0 5Zm8.5-.625A2.63 2.63 0 0 1 10.375 16H9a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1.375A2.63 2.63 0 0 1 13 11.625v1.75ZM17 12a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-1v1h1Z" />
                              </svg>
                              <span className="font-semibold">
                                {fileData.title}
                              </span>
                            </li>
                          </a>
                        ))}
                      </ul>
                      <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                    </>
                  ) : (
                    <>
                      {withSubscription ? (
                        <>
                          <a
                            onClick={() => setOpenSubscriptionModal(true)}
                            className="block w-full cursor-pointer text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                          >
                            Postani Premium član
                          </a>
                          <SubscriptionModal
                            user={user}
                            show={openSubscriptionModal}
                            onClose={() => setOpenSubscriptionModal(false)}
                          />
                        </>
                      ) : (
                        <a
                          className="block w-full text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                          href={`/app/checkout/challenge/${id}`}
                        >
                          Kupi odmah
                        </a>
                      )}

                      <div className="pt-3 text-center flex flex-row itmes-center justify-center">
                        <img
                          src="https://www.svgrepo.com/show/126582/warning.svg"
                          className="w-3 opacity-80 invert"
                        />

                        {withSubscription ? (
                          <span className="block text-xs font-semibold ml-2 text-zinc-400">
                            Uključeno u Premium pretplatu
                          </span>
                        ) : (
                          <span className="block text-xs font-semibold ml-2 text-zinc-400">
                            Nije uključeno u Premium pretplatu
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  <div className="hidden text-sm text-white w-full mt-3 md:mt-6 mb-6">
                    <p className="text-xs">
                      Kako bi vaš novac bio siguran, nikad ne obavljate novčane
                      transakcije niti komunicirajte izvan stranice.
                      <a href="#" className="font-bold underline">
                        Saznajte više
                      </a>
                    </p>
                  </div>
                  <div className="flex flex-row justify-around hidden">
                    <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                      <img
                        src="https://www.svgrepo.com/show/84870/link.svg"
                        className="w-4 mr-2"
                        alt=""
                      />
                      <p className="text-sm text-black font-semibold">
                        Copy link
                      </p>
                    </div>
                    <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                      <img
                        src="https://www.svgrepo.com/show/35048/email.svg"
                        className="w-4 mr-2"
                        alt=""
                      />
                      <p className="text-sm text-black font-semibold">
                        Send email
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ChallengeDetail;
