import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/style.css";

import TreningList from "../Trainings/TrainingList";
import ChallengeList from "../Challenges/ChallengeList";
import EbookList from "../Ebooks/EbookList";
import SubscriptionBanner from "../Subscription/SubscriptionBanner";
import PartnerPromoCodes from "./PartnerPromoCodes";

import { Carousel } from "flowbite-react";

const Dashboard = ({ user }) => {
  const [bootyPurchaseData, setBootyPurchaseData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchasedResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=2&product_category=training`
        );
        if (purchasedResponse.data.status === "success") {
          // Ako je status uspešan, sačuvajte podatke u state
          setBootyPurchaseData(purchasedResponse.data);
        }
      } catch (err) {
        console.error("Error fetching training:", err);
      }
    };

    fetchData();
  }, [user.id]); // Ako želite da se efekat pokrene ponovo kada se promeni user.id, dodajte ga u zavisnosti

  return (
    <div>
      <section
        className="relative h-screen w-full bg-cover bg-center"
        style={{
          backgroundImage: `url('https://medianfiles.com/wp-content/uploads/2024/02/WhatsApp-Image-2024-02-01-at-19.44.33-e1706892377873.jpeg')`,
        }}
      >
        <div className="z-20 mx-auto w-screen max-w-screen-xl absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
          <div className="flex items-center">
            <div className="hidden md:block relative">
              <img
                className="h-[33rem]"
                src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-03-22-at-08.28.00.jpeg"
              ></img>
              <span class="hidden text-center absolute top-4 left-0 right-0 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                Tea Kravaršćan
              </span>
            </div>
            <div className="p-8 w-full lg:w-96">
              <div className="flex gap-2">
                <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-red-800  text-red-100">
                  Novo
                </span>
              </div>
              <h1 className="text-anton uppercase text-white text-6xl z-50 mt-6">
                Booty <span>Challenge</span>
              </h1>
              <p className="text-base text-white  block mt-4">
                8-tjedni program treninga za donji dio tijela sa fokusom na
                stražnjicu. GYM Program je namjenjen svima bilo da ste početnik
                ili napredni vježbač. Uključuje 4 treninga tjedno s
                demonstracijom vježbi te uključuje i vježbe za gornji dio tijela
                i core.
              </p>
              {bootyPurchaseData == null && (
                <div className="">
                  <span className="text-white flex items-center gap-1 text-lg font-semibold mt-6">
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7c0-1.1.9-2 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6c.6 0 1 .4 1 1v3a1 1 0 1 1-2 0v-3c0-.6.4-1 1-1Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    €40.00
                  </span>
                  <span className="text-white flex items-center gap-1 text-base">
                    Cijena za članove:{" "}
                    <span className="font-semibold">€24.00</span>
                  </span>
                  <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mt-6">
                    <a
                      href="/app/checkout/training/2"
                      className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 focus:ring-amber-300 bg-white"
                    >
                      Kupi odmah
                    </a>
                  </div>
                </div>
              )}
              {bootyPurchaseData != null && (
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mt-6">
                  <a
                    href="/app/trainings/2"
                    className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center rounded-lg border focus:ring-4 focus:ring-amber-300 bg-white"
                  >
                    Vidi odmah
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="absolute w-full h-96 bottom-0 bg-gradient-to-t from-zinc-900"></div>
        <div className="absolute w-full h-96 top-0 bg-gradient-to-b from-zinc-900"></div>
      </section>

      <section className="">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6 mt-16">
          <TreningList />
        </div>
      </section>

      <SubscriptionBanner user={user} />

      <section className="">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
            Treninzi
          </h2>
          <ChallengeList user={user} />
        </div>
      </section>

      <section className="">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <h2 className="text-anton mt-12 mb-8 text-4xl md:text-4xl text-white">
            Ebooks
          </h2>
          <EbookList />
        </div>
      </section>

      <section className="m-4">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 shadow">
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slideInterval={2500}>
              <img
                src="https://medianfiles.com/wp-content/uploads/2024/03/WhatsApp-Image-2024-02-17-at-14.15.47.jpeg"
                alt="..."
              />
            </Carousel>
          </div>
        </div>
      </section>

      <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
        <PartnerPromoCodes user={user} />
      </div>
    </div>
  );
};

export default Dashboard;
