import React, { useState, useEffect } from "react";
import "../../style/style.css";
import { useParams } from "react-router-dom";
import fetchEbooks from "../../api/fetchEbook";
import fetchSubscriptionmetabyuser from "../../api/fetchSubscriptionmetabyuser";
import fetchFile from "../../api/fetchFile";
import fetchMediatoken from "../../api/fetchMediatoken";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import SubscriptionModal from "../Subscription/SubscriptionModal";

const EbookDetail = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ebookData, setEbookData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [withSubscription, setWithSubscription] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEbooks.get(`/${id}`);
        setEbookData(response.data.data.ebook);

        if (response.data.data.ebook.with_subscription === true) {
          setWithSubscription(true);
          try {
            const responseSubscription = await fetchSubscriptionmetabyuser.get(
              `/${user.id}`
            );
            if (responseSubscription.data.status === "success") {
              setSubscriptionData(responseSubscription.data.data.subscription);
              setIsPurchased(true);
            }
          } catch (error) {
            setIsPurchased(false);
          }
        } else {
          // Provjeri je li korisnik kupio e-knjigu
          const purchasedResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/user/${user.id}/purchased?product_id=${id}&product_category=ebook`
          );
          if (purchasedResponse.data.status === "success") {
            const datePaidString = purchasedResponse.data.results[0].date_paid;
            const datePaid = new Date(datePaidString);
            const duration = response.data.data.training.duration;
            const dateDue = new Date(datePaid);
            dateDue.setDate(datePaid.getDate() + duration);
            const currentDate = new Date();

            if (currentDate > dateDue) {
              setIsPurchased(false);
            } else {
              console.log(dateDue);
              setIsPurchased(true);
            }
          }
        }

        const responseFile = await fetchFile.get(
          `/${response.data.data.ebook.files_ids}`
        );
        setFileData(responseFile.data.data.file);
        console.log(responseFile.data.data.file);
      } catch (err) {
        console.error("Error fetching ebook:", err);
      }
    };

    fetchData();
  }, [id, user]);

  const openPdf = async (fileID) => {
    try {
      const response = await fetchMediatoken.get(`/${user.id}/${fileID}`);
      const mediaToken = response.data.mediatoken;
      if (mediaToken) {
        // Redirekcija na /app/player/${video.id}/?mediatoken=
        navigate(`/app/pdf/${fileID}/${mediaToken}`);
      } else {
        console.error("Media token is not generated.");
      }
    } catch (error) {
      console.error("Error generating video token:", error);
    }
  };

  // Dodajte povratnu vrijednost JSX-a ovisno o ebookData
  return (
    <div>
      {ebookData ? (
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="md:w-[69%]">
              <div className="flex w-full mt-4">
                <div
                  className="max-w-xs w-full aspect-[210/297] mr-1 bg-center bg-no-repeat bg-cover rounded-lg"
                  style={{
                    backgroundImage:
                      "url('https://medianfiles.com/wp-content/uploads/2024/03/Snimka-zaslona-2024-03-22-053910.png')",
                  }}
                ></div>
                <div
                  className="max-w-xs w-full aspect-[210/297] mr-1 bg-center bg-no-repeat bg-cover rounded-lg"
                  style={{
                    backgroundImage:
                      "url('https://medianfiles.com/wp-content/uploads/2024/03/Snimka-zaslona-2024-03-22-053950.png')",
                  }}
                ></div>
                <div
                  className="max-w-xs w-full aspect-[210/297] mr-1 bg-center bg-no-repeat bg-cover rounded-lg"
                  style={{
                    backgroundImage: "url('')",
                  }}
                ></div>
              </div>
              <div className="py-6">
                <nav className="flex mb-4" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                      <a
                        href="#"
                        className="inline-flex items-center text-sm font-medium text-zinc-700 hover:text-blue-600"
                      >
                        <svg
                          className="w-3 h-3 mr-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        Početna
                      </a>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg
                          className="w-3 h-3 text-zinc-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <a
                          href="#"
                          className="ml-1 text-sm font-medium text-zinc-700 hover:text-blue-600 md:ml-2"
                        >
                          Ebooks
                        </a>
                      </div>
                    </li>
                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg
                          className="w-3 h-3 text-zinc-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <span className="ml-1 text-sm font-medium md:ml-2 text-zinc-400">
                          {ebookData.title}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
                <h2 className="text-crimson mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl text-white">
                  {ebookData.title}
                </h2>
              </div>
              <div>
                <p className="mb-3 text-zinc-400">{ebookData.description}</p>
              </div>
            </div>
            <div className="relative md:w-[30%]">
              <div className="mx-auto md:shadow-lg rounded-xl md:w-full bg-zinc-800 text-center md:text-left p-2 md:p-6 md:border border-zinc-900 sticky top-4">
                {!isPurchased && !withSubscription ? (
                  <>
                    <p className="block text-white text-3xl font-bold py-2">
                      €{ebookData.price}
                    </p>
                    <div className="flex flex-col">
                      <span className="text-sm text-white">
                        Jednokratno plaćanje
                      </span>
                      <span className="text-xs text-zinc-400">
                        PDV uključen u cijenu
                      </span>
                    </div>
                    <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                  </>
                ) : (
                  <></>
                )}

                <ul className="hidden text-sm text-white w-full mt-6 mb-6">
                  <li className="mb-3 flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#10b981"
                      viewBox="0 0 1792 1792"
                    >
                      <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                    </svg>
                    <div>2.5 hours of video</div>
                  </li>
                  <li className="mb-3 flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#10b981"
                      viewBox="0 0 1792 1792"
                    >
                      <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                    </svg>
                    <div>Content available on all devices</div>
                  </li>
                  <li className="mb-3 flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#10b981"
                      viewBox="0 0 1792 1792"
                    >
                      <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path>
                    </svg>
                    <div>Content available on all devices</div>
                  </li>
                </ul>

                {isPurchased ? (
                  <>
                    <span className="hidden md:block w-full bg-zinc-100 h-1 rounded-lg my-2"></span>
                    <ul className="block text-sm text-white w-full mt-6 mb-6">
                      {fileData && (
                        <a
                          key={fileData.id}
                          target="_blank"
                          href={fileData.url}
                          rel="noopener noreferrer"
                        >
                          <li className="mb-3 flex items-center space-x-4 bg-zinc-700 hover:bg-zinc-600 p-4 rounded-lg cursor-pointer">
                            <svg
                              className="w-6 h-6 text-zinc-100"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M4.5 11H4v1h.5a.5.5 0 0 0 0-1ZM7 5V.13a2.96 2.96 0 0 0-1.293.749L2.879 3.707A2.96 2.96 0 0 0 2.13 5H7Zm3.375 6H10v3h.375a.624.624 0 0 0 .625-.625v-1.75a.624.624 0 0 0-.625-.625Z" />
                              <path d="M19 7h-1V2a1.97 1.97 0 0 0-1.933-2H9v5a2 2 0 0 1-2 2H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h1a1.969 1.969 0 0 0 1.933 2h12.134c1.1 0 1.7-1.236 1.856-1.614a.988.988 0 0 0 .07-.386H19a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM4.5 14H4v1a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1h1.5a2.5 2.5 0 1 1 0 5Zm8.5-.625A2.63 2.63 0 0 1 10.375 16H9a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1.375A2.63 2.63 0 0 1 13 11.625v1.75ZM17 12a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-1v1h1Z" />
                            </svg>
                            <span className="font-semibold">
                              {fileData.title}
                            </span>
                          </li>
                        </a>
                      )}
                    </ul>
                  </>
                ) : (
                  <>
                    {withSubscription ? (
                      <>
                        <a
                          onClick={() => setOpenSubscriptionModal(true)}
                          className="block w-full cursor-pointer text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                        >
                          Postani Premium član
                        </a>
                        <SubscriptionModal
                          user={user}
                          show={openSubscriptionModal}
                          onClose={() => setOpenSubscriptionModal(false)}
                        />
                      </>
                    ) : (
                      <a
                        className="block w-full text-center font-bold m-auto px-12 py-3 text-sm border border-black rounded-lg bg-white text-black"
                        href={`/app/checkout/ebook/${id}`}
                      >
                        Kupi odmah
                      </a>
                    )}

                    <div className="pt-3 text-center flex flex-row itmes-center justify-center">
                      <img
                        src="https://www.svgrepo.com/show/126582/warning.svg"
                        className="w-3 opacity-80 invert"
                      />

                      {withSubscription ? (
                        <span className="block text-xs font-semibold ml-2 text-zinc-400">
                          Uključeno u Premium pretplatu
                        </span>
                      ) : (
                        <span className="block text-xs font-semibold ml-2 text-zinc-400">
                          Nije uključeno u Premium pretplatu
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div className="hidden text-sm text-white w-full mt-3 md:mt-6">
                  <p className="text-xs">
                    Kako bi vaš novac bio siguran, nikad ne obavljate novčane
                    transakcije niti komunicirajte izvan stranice.
                    <a href="#" className="font-bold underline">
                      Saznajte više
                    </a>
                  </p>
                </div>
                <div className="flex flex-row justify-around hidden">
                  <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                    <img
                      src="https://www.svgrepo.com/show/84870/link.svg"
                      className="w-4 mr-2"
                      alt=""
                    />
                    <p className="text-sm text-black font-semibold">
                      Copy link
                    </p>
                  </div>
                  <div className="py-3 flex flex-row items-center cursor-pointer rounded-xl bg-slate-50 hover:bg-slate-100 px-4">
                    <img
                      src="https://www.svgrepo.com/show/35048/email.svg"
                      className="w-4 mr-2"
                      alt=""
                    />
                    <p className="text-sm text-black font-semibold">
                      Send email
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default EbookDetail;
